<template>
    <div class="con">
        <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
        <div class="right">
            <div class="switch">
                <div class="switch-item">
                    <span class="mg-rt50">打印机名称</span>
                    <input v-model="deviceModel" class="switchinput" placeholder="请输入打印机名称" :disabled="bind_status == 0" />
                </div>
                <div class="switch-item" :class="{'devicetype-switch-item': id && bind_status == 0}">
                    <span>打印机类型</span>
                    <van-radio-group v-model="deviceType" direction="horizontal" @change="changeDeviceType" :disabled="bind_status == 0">
                        <van-radio name="1" icon-size="3rem" :disabled="bind_status == 0" v-if="(isApp == 'android' || isApp == 'mt_android') || (id && bind_status == 0)">USB打印机(安卓)</van-radio>
                        <van-radio name="3" icon-size="3rem" :disabled="bind_status == 0" v-if="(isApp == 'android' || isApp == 'mt_android') || (id && bind_status == 0)">蓝牙打印机(安卓)</van-radio>
                        <van-radio name="10" icon-size="3rem" :disabled="bind_status == 0" v-if="isApp == 'mt_android' || (id && bind_status == 0)">内置打印机(美团)</van-radio>
                        <van-radio name="4" icon-size="3rem" :disabled="bind_status == 0" v-if="isApp == 'windows' || (id && bind_status == 0)">网口打印机(Windows)</van-radio>
                        <van-radio name="5" icon-size="3rem" :disabled="bind_status == 0" v-if="isApp == 'windows' || (id && bind_status == 0)">驱动打印机(Windows)</van-radio>
                        <van-radio name="6" icon-size="3rem" :disabled="bind_status == 0">大趋云打印机(通用)</van-radio>
                        <van-radio name="11" icon-size="3rem" :disabled="bind_status == 0">佳博云打印机(通用)</van-radio>
                    </van-radio-group>
                </div>
                <div v-if="deviceType == '4'" class="device-ip-item">
                    <div class="switch-item">
                        <span>IP地址</span>
                        <input v-model="deviceIP" class="switchinput" placeholder="请输入IP地址" @input="handleInputIp" :disabled="disa" />
                    </div>
                    <div v-if="IpErrorMsg != ''" class="ft-20 color-e60012 mg-bt25 device-ip-errormsg">{{ IpErrorMsg }}</div>
                </div>
                <div class="switch-item printset-switch-item" v-if="((id && bind_status == 1) || !id) && (isApp == 'android' || isApp == 'mt_android') && (deviceType == '1' || deviceType == '3')">
                    <span>选择打印机</span>
                    <van-radio-group v-model="printer" @change="changePrinter" direction="horizontal">
                        <van-radio v-for="(item, index) in outDeviceList" :key="index" :name="index" icon-size="3rem" class="printset">
                            <span v-if="deviceType == '1'">{{ item.deviceProductName }}</span>
                            <span v-if="deviceType == '3'">{{ item.deviceName }}</span>
                        </van-radio>
                    </van-radio-group>
                </div>
                <template v-if="deviceType == '6'">
                    <div class="switch-item">
                        <span>打印机编码(SN)</span>
                        <input v-model="deviceSN" class="switchinput" placeholder="请输入打印机编码(SN)" :disabled="id" />
                    </div>
                    <div class="switch-item">
                        <span>秘钥(key)</span>
                        <input v-model="deviceKey" class="switchinput" placeholder="请输入秘钥(key)" :disabled="id" />
                    </div>
                </template>
                <template v-if="deviceType == '11'">
                    <div class="switch-item">
                        <span>打印机编号</span>
                        <input v-model="deviceSN" class="switchinput" placeholder="请输入打印机编号" :disabled="id" />
                    </div>
                </template>
                <div class="switch-item" v-if="id && bind_status == 0">
                    <span>选择打印机</span>
                    <input v-model="printer_name_show" class="switchinput" disabled />
                </div>
                <div class="switch-item">
                    <span>打印纸尺寸</span>
                    <van-radio-group v-model="deviceSize" direction="horizontal" :disabled="bind_status == 0">
                        <van-radio :name="58" icon-size="3rem" class="mg-rt40" :disabled="bind_status == 0">58mm</van-radio>
                        <van-radio v-if="deviceType != '6' && deviceType != '11'" :name="80" icon-size="3rem" :disabled="bind_status == 0">80mm</van-radio>
                    </van-radio-group>
                </div>

                <div class="spt-btns">
                    <button v-if="bind_status == 1" class="printtest-btn bg-3DB956 mg-rt70 color-fff" @click="print">测试打印</button>
                    <button v-if="!id" class="printtest-btn mg-rt70 bg-EAEAEA color-666" @click="openDialog('goback')">取消</button>
                    <button v-if="bind_status == 1 || (id && shop.user_model == 1)" class="printtest-btn mg-rt70 bg-e60012 color-fff" @click="openDialog('delete')">删除</button>
                    <button v-if="!id || bind_status == 1" class="printtest-btn bg-1588F5 mg-rt70 color-fff" @click="save">保存</button>
                </div>
            </div>
        </div>

        <popupOfDialog
            v-if="showDialog"
            :type="dialogType"
            :title="dialogTitle"
            :message="dialogMsg"
            :data="dialogData"
            @closePopDialog="closePopDialog"
            @confirmPopDialog="confirmPopDialog"
        ></popupOfDialog>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import popupOfDialog from '@/views/component/popupOfDialog.vue';
import {
    searchDevice,
    connectUSB,
    connectBluetooth,
    deviceInfo,
} from '@/utils/outPrint'
import { checkUsbConnect, getPrinterPaperType } from '@/utils/outPrint';
import titlebar from "@/views/component/titlebar";
import { formatTime, getPrinterType } from '../../utils/util';
export default {
    name: "setPrintType",
    components: {
        titlebar,
        popupOfDialog,
    },
    data() {
        return {
            driverPrinters: [], // 打印机列表
            elec: false,
            send: false,
            disab: false,
            deviceType: '', // 打印机类型
            deviceModel: '', // 打印机名称
            isPrint_zcd: false, // 是否打印做菜单
            takingData:{
                "type":"2",
                "shop_name":"体验店",
                "staff":"员工 10003",
                "order_num":"202311071234567891234567",
                "s_time":formatTime(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                "data":[
                    ["测试商品","3.7","1份","3.7"]
                ],
                "price":"3.70",
                "total_sum":"1",
                "total_price":"3.70",
                "pay_type":"现金",
                "user_name":"",
                "user_left_price":"",
                "member_left_day":"",
                "bank_left_price":"",
                "address":"XXXXX",
                "tel":"0000",
                "actual_amount": "3.70",
                "pay_type_text": "3",
            },
            navtitle: '',
            id: null,
            info: null,
            deviceIP: '',
            deviceSize: 0,
            showDialog: false,
            dialogTitle: '',
            dialogMsg: '',
            dialogType: '',
            dialogData: {},
            isApp: '', // 是否是在APP内
            printer: -1,
            printer_name: '',
            printer_address: '',
            bind_status: '',
            printer_name_show: '',
            printer_address_show: '',
            IpErrorMsg: '',
            receiveMiniOrder: true, // 是否接收小程序订单
            deviceSN: '',
            deviceKey: '',
            page_name: '',
        }
    },
    computed: {
        ...mapGetters({ shop: 'shop', outDeviceList: 'outDeviceList' }),
        disa() {
            return this.shop.user_model == 0 && this.bind_status == 0;
        },
        localIp() {
            let ip = '';
            if (this.isApp == 'android' || this.isApp == 'mt_android') {
                ip = this.$store.state.deviceInfo.ip || '';
            } else if (this.isApp == 'windows') {
                ip = this.$store.state.deviceIp || '';
            }
            return ip;
        },
    },
    methods: {
        handleInputIp() {
            this.IpErrorMsg = '';
            const value = this.checkDeviceIp();
            if (value) {
                this.IpErrorMsg = '打印机IP与收银机IP须在同一网段';
            } else {
                this.IpErrorMsg = '';
            }
        },
        checkDeviceIp() {
            let value = false;
            if (this.deviceIP != '' && this.localIp != '') {
                const deviceIpArr = this.deviceIP.split('.');
                // console.log('deviceIpArr', deviceIpArr);
                const localIpArr = this.localIp.split('.');
                if (deviceIpArr.length != localIpArr.length) {
                    value = true;
                    // console.log('check 1');
                } else {
                    value = false;
                    // console.log('check 2');
                    if (deviceIpArr.indexOf('') > -1) {
                        // console.log('check 3');
                        value = true;
                    } else {
                        for (let i = 0; i < localIpArr.length; i++) {
                            const element = localIpArr[i];
                            if (i <= 2 && element != deviceIpArr[i]) {
                                // console.log('check 4');
                                value = true;
                                return value;
                            }
                        }
                    }
                }
            }
            return value;
        },
        async changeDeviceType(e) {
            if (e == '4' || e == '5' || e == '6' || (this.id && this.bind_status == 0)) {
                return;
            }
            if (e == '10') {
                const size_ =  await getPrinterPaperType();
                if (size_.code == 1) {
                    this.deviceSize = parseInt(size_.msg);
                }
                return;
            }
            this.deviceType = e;
            let v = '';
            switch (this.deviceType) {
                case '1':
                    v = 'usb'
                    break;
                case '3':
                    v = 'bluetooth'
                    break;
            }
            await searchDevice(v);
            if (this.outDeviceList.length < 1) {
                this.$toast('未查找到设备');
            }
            console.log('打印设备', this.outDeviceList);
        },
        changePrinter(e) {
            this.printer = e;
            if (this.deviceType == '1') { // USB
                if (this.outDeviceList.length > 0 && this.printer > -1) {
                    this.printer_name = this.outDeviceList[this.printer].deviceProductName;
                    this.printer_address = this.outDeviceList[this.printer].deviceName;
                    connectUSB(this.printer_name, this.printer_address);
                }
            } else if (this.deviceType == '3') { // 蓝牙
                this.printer_name = this.outDeviceList[this.printer].deviceName;
                this.printer_address = this.outDeviceList[this.printer].address;
                connectBluetooth(this.printer_name, this.printer_address);
            }
        },
        confirmPopDialog(data) {
            if (data.type == 'delete') {
                this.deletePrint();
            } else if (data.type == 'goback') {
                this.goBack();
            }
            this.closePopDialog();
        },
        closePopDialog(data) {
            this.showDialog = false;
        },
        openDialog(type) {
            this.dialogType = type;
            if (type == 'delete') {
                this.dialogTitle = '删除前台打印机';
                this.dialogMsg = `确认删除此打印机吗？`;
            } else if (type == 'goback') {
                this.dialogTitle = '返回';
                this.dialogMsg = `系统不会保存您的更改，确认返回吗？`;
            }
            this.showDialog = true;
        },
        deletePrint() {
            let params = {
                ids: this.id,
                cloud_sn: this.deviceSN,
            }
            this.$api.dt_deletePrinter(params).then(res => {
                if (res.code == 1) {
                    this.goBack();
                    this.$toast('删除打印机成功，门店设置已更新')
                    localStorage.removeItem('printType')
                    localStorage.removeItem('qiantaiPrintIp')
                    localStorage.removeItem('qiantaiPrintSize')
                    localStorage.removeItem('printer_name')
                    localStorage.removeItem('printer_address')
                } else {
                    this.$toast(res.msg);
                }
            })
        },
        goBack() {
            this.$router.replace({
                name: 'featureSet',
                query: {
                    name: 'featureSet',
                    active: this.$route.query.active,
                },
            })
        },
        setData() {
            this.$api.dt_getPrinterInfo({
                id: this.id
            }).then(async res => {
                if (res.code == 1) {
                    this.deviceModel = res.data.name;
                    this.deviceType = getPrinterType(res.data.printer_type.toString(), 1);
                    this.deviceIP = res.data.ip;
                    this.isPrint_zcd = res.data.print_food_menu == '1';
                    this.elec = res.data.print_invoice == '1';
                    this.deviceSize = parseInt(res.data.size);
                    this.receiveMiniOrder = res.data.is_bind_miniprint == '1';

                    this.deviceSN = res.data.cloud_sn || '';
                    this.deviceKey = res.data.cloud_key || '';

                    if (this.bind_status == 0) {
                        this.printer_name_show = res.data.printer_name;
                        this.printer_address_show = res.data.printer_address;
                    } else if (this.bind_status == 1) {
                        this.printer_name = res.data.printer_name;
                        this.printer_address = res.data.printer_address;
                        if (this.deviceType == '1') { // USB
                            await searchDevice('usb');
                            if (this.outDeviceList.length > 0) {
                                const index = this.outDeviceList.findIndex(e => (e.deviceProductName == res.data.printer_name && e.deviceName == res.data.printer_address));
                                if (index > -1) {
                                    this.printer = index;
                                }
                            } else {
                                this.$toast('未查找到设备');
                            }
                        } else if (this.deviceType == '3') { // 蓝牙
                            await searchDevice('bluetooth');
                            if (this.outDeviceList.length > 0) {
                                const index = this.outDeviceList.findIndex(e => (e.deviceName == res.data.printer_name && e.address == res.data.printer_address));
                                if (index > -1) {
                                    this.printer = index;
                                }
                            } else {
                                this.$toast('未查找到设备');
                            }
                        }
                    }
                } else {
                    this.$toast(res.msg);
                }
            })
        },
        async save() {
            if (this.deviceModel == '') {
                this.$toast('请输入打印机名称')
                return
            }
            if (this.deviceType == '') {
                this.$toast('请选择打印机类型')
                return
            }
            if (this.deviceType == '4' && this.deviceIP == '') {
                this.$toast('请输入打印机IP地址')
                return
            }
            if (this.deviceType == '4') {
                const value = this.checkDeviceIp();
                if (value) {
                    this.$toast('请修改打印机ip，保证和收银机在同一网段');
                    return;
                }
            }
            if (this.deviceType == '6' && this.deviceSN == '') {
                this.$toast('请输入打印机编码(SN)')
                return
            }
            if (this.deviceType == '6' && this.deviceKey == '') {
                this.$toast('请输入秘钥(key)')
                return
            }
            if (this.deviceType == '11' && this.deviceSN == '') {
                this.$toast('请输入打印机编号')
                return
            }
            if (this.deviceSize == 0 || !this.deviceSize || this.deviceSize == '') {
                this.$toast('请选择打印纸尺寸')
                return
            }
            if ((this.deviceType == 1 || this.deviceType == 3) && (this.printer_name == '' || this.printer_address == '')) {
                this.$toast('请选择打印机')
                return
            }
            if ((this.isApp == 'android' || this.isApp == 'mt_android') && this.deviceType == 1) {
                const tagR = await checkUsbConnect();
                if (tagR.code != 1) {
                    this.printer = -1;
                    return;
                }
            }

            let params = {
                name: this.deviceModel,
                printer_type: getPrinterType(this.deviceType.toString(), 2),
                status: 1,
                type: 1, // 类型 1，前台打印机
                size: this.deviceSize,
                device_mac: this.$store.state.deviceMac,
                hall_ip: this.$store.state.deviceIp,
            }

            if (this.deviceType == '4') {
                params.ip = this.deviceIP; // 打印机ip
            }

            if (this.deviceType == 1 || this.deviceType == 3) {
                params.printer_name = this.printer_name;
                params.printer_address = this.printer_address;
            }

            if (this.id) {
                params.id = this.id;
                this.$api.dt_editPrinter(params).then(res => {
                    if (res.code == 1) {
                        localStorage.removeItem('qiantaiPrintIp')
                        localStorage.removeItem('qiantaiPrintSize')

                        localStorage.setItem('printer_id', this.id);

                        if (this.deviceType == '4') {
                            localStorage.setItem('qiantaiPrintIp', this.deviceIP)
                            localStorage.setItem('qiantaiPrintSize', this.deviceSize)
                        } else if (this.deviceType == '1' || this.deviceType == '5' || this.deviceType == '10') {
                            localStorage.setItem('qiantaiPrintSize', this.deviceSize)
                        } else if (this.deviceType == '6') {
                            localStorage.setItem('qtDeviceSN', this.deviceSN);
                            localStorage.setItem('qtDeviceKey', this.deviceKey);
                        } else if (this.deviceType == '11') {
                            localStorage.setItem('qtDeviceSN', this.deviceSN);
                        }

                        if (this.deviceType != localStorage.printType) {
                            localStorage.setItem('printType', this.deviceType);
                        }
                        this.goBack();
                        this.$toast('编辑打印机成功，门店设置已更新')
                    } else {
                        this.$toast(res.msg);
                    }
                })
            } else {
                if (this.deviceType == '6') {
                    params.cloud_sn = this.deviceSN;
                    params.cloud_key = this.deviceKey;
                }
                if (this.deviceType == '11') {
                    params.cloud_sn = this.deviceSN;
                }
                this.$api.dt_addPrinter(params).then(async res => {
                    if (res.code == 1) {
                        localStorage.setItem('printType', this.deviceType);
                        localStorage.setItem('printer_id', res.data.id || 0);
                        if (this.deviceType == '4') {
                            localStorage.setItem('qiantaiPrintIp', this.deviceIP)
                            localStorage.setItem('qiantaiPrintSize', this.deviceSize)
                        } else if (this.deviceType == '1' || this.deviceType == '5' || this.deviceType == '10') {
                            localStorage.setItem('qiantaiPrintSize', this.deviceSize)
                        } else if (this.deviceType == '6') {
                            localStorage.setItem('qtDeviceSN', this.deviceSN);
                            localStorage.setItem('qtDeviceKey', this.deviceKey);
                        } else if (this.deviceType == '11') {
                            localStorage.setItem('qtDeviceSN', this.deviceSN);
                        }
                        this.goBack();
                        this.$toast('添加打印机成功，门店设置已更新')
                    } else {
                        this.$toast(res.msg);
                    }
                })
            }
        },
        // 测试打印
        async print() {
            if (this.deviceType == '') {
                this.$toast('请选择打印机类型')
                return
            }
            if (this.deviceType == '4' && this.deviceIP == '') {
                this.$toast('请输入打印机IP地址')
                return
            }
            if (this.deviceType == '4' && this.deviceSize == 0) {
                this.$toast('请选择打印纸尺寸')
                return
            }
            if ((this.isApp == 'android' || this.isApp == 'mt_android') && this.deviceType == 1) {
                const tagR = await checkUsbConnect();
                if (tagR.code != 1) {
                    this.printer = -1;
                    return;
                }
            }
            this.$toast.loading({
                message: '正在发送打印任务...',
                forbidClick: true,
                duration: 1000
            })
            // 结账单
            this.$printFunc.printReceipt(this.takingData);
        },
        async handleReadyIos() {
            this.$store.commit('updateDeviceMac', device.uuid);
        },
    },
    async created() {
        this.page_name = this.$route.query.name || '';
        console.log(this.shop);
        this.isApp = localStorage.nowDevice || '';
        this.id = this.$route.query.id || null;
        this.bind_status = this.$route.query.bind_status;
        if (this.isApp == 'android') {
            deviceInfo();
        } else if (this.isApp == 'windows') {
            await this.$device.getLocalIP();
        } else if(this.isApp == 'ios') {
            document.addEventListener("deviceready", this.handleReadyIos, false);
        } else if (this.isApp == 'mt_android') {
            deviceInfo();
            this.deviceType = '10';
            const size_ =  await getPrinterPaperType();
            if (size_.code == 1) {
                this.deviceSize = parseInt(size_.msg);
            }
        }
        if (this.id) {
            this.setData();
        }
    }
}
</script>

<style lang="less" scoped>
.switchinput:disabled {
    background: #fff;
    color: #c8c9cc;
    opacity: 1;
    -webkit-text-fill-color: #c8c9cc;
}
.printset-switch-item {
    min-height: 9.5rem;
    height: auto !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    .printset {
        line-height: 2;
        margin-bottom: 2rem;
    }
}
.devicetype-switch-item {
    min-height: 9.5rem;
    height: auto !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    /deep/.van-radio-group {
        width: 75%;
        // justify-content: flex-end;

    }
    /deep/.van-radio {
        height: 4rem;
        line-height: 2;
        margin-bottom: 1.5rem;
    }
}
.printtest-btn {
    display: block;
    font-size: 2.4rem;
    width: 30rem;
    height: 7rem;
    border-radius: 0.8rem;
    // color: #fff;
    &:active {
        background: #cf1d2c;
    }
}

.reconnect-btn {
    width: 4rem;
    height: 1rem;
}
.con {
    height: 100%;
}

.right {
    margin-top: 1.5rem;
    height: calc(100% - 10.6rem);
    overflow-y: scroll;
    overflow-x: hidden;

    .switch {
        // margin-top: 0.2rem;
        background: #fff;
        padding-bottom: 2rem;

        .switch-item {
            font-size: 2.4rem;
            height: 9.5rem;
            background-color: #fff;
            // margin-top: 0.05rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1.2rem;
            border-bottom: 0.15rem solid #eeeeee;
            width: calc(100% - 16.8rem);
            margin: 0 4rem;
        }

        .switch-item:last-child {
            border-bottom: none !important;
        }

        .spt-btns {
            display: flex;
            margin: 5rem auto 2.5rem;
            justify-content: center;
        }

        .switchinput {
            // border: 0.01rem #eeeeee solid;
            border-radius: 0.8rem;
            height: 70%;
            width: 80%;
        }

        /deep/.van-radio-group {
            height: 100%;
            width: 80%;
        }

        .device-ip-item {
            border-bottom: 0.15rem solid #eeeeee;
            margin: 0 10.4rem 0 4.4rem;

            .switch-item {
                border-bottom-style: none !important;
                margin: 0;
                width: 100%;
            }

            .device-ip-errormsg {
                width: 79%;
                margin-left: auto;
                text-align: left;
            }
        }
    }
}
</style>
